import { CartBaseController } from './cart_base_controller'

export default class extends CartBaseController {
  static targets = [ 'order' ]

  async connect () {
    if (this.clear) this.storage.clear()

    if (!this.template) return

    const order = this.cart.data.attributes
    const products = this.products
    const site = await this.site()
    const shipping_address = JSON.parse(this.storage.getItem('shipping_address'))

    const data = {
      order,
      products,
      site,
      shipping_address
    }

    this.render(data)
  }

  render (data = {}) {
    fetch(this.template).then(r => r.text()).then(template => {
      this.engine.parseAndRender(template, data).then(html => this.orderTarget.innerHTML = html)
    })
  }

  get template () {
    return this.element.dataset.template
  }

  get clear () {
    return this.element.dataset.clear
  }
}
