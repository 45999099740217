import { Controller } from 'stimulus'

/*
 * Mantiene el stock actualizado, consultando a la API.
 *
 * * Obtiene todas las variantes en el controlador
 * * Consulta a la API
 * * Actualiza stock y precio
 * * Deshabilita botón si no está en stock
 */
export default class extends Controller {
  static targets = [ 'product' ]

  async connect () {
    if (this.variant_ids.length === 0) return

    const ids = this.variant_ids.join(',')
    const filter = { ids }
    let response = await window.spree.products.list({ filter })

    // TODO: Gestionar errores
    if (response.isFail()) {
      console.error(response.fail())
      return
    }

    this.update_local_products(response.success().data)

    // Recorrer todas las páginas
    // XXX: Podríamos usar next pero la página 1 siempre se devuelve a
    // sí misma y entraríamos en un loop infinito.
    for (let page = 2; page <= response.success().meta.total_pages; page++) {
      response = await window.spree.products.list({ filter, page })

      // TODO: Gestionar errores
      if (response.isFail()) {
        console.error(response.fail())
        continue
      }

      this.update_local_products(response.success().data)
    }
  }

  /*
   * La lista de todas las variantes incluidas en el controlador que no
   * estén vacías.
   *
   * @return [Array]
   */
  get variant_ids () {
    if (!this._variant_ids) this._variant_ids = [...new Set(this.productTargets.map(p=> p.dataset.cartVariantId).filter(x => x.length > 0))]

    return this._variant_ids
  }

  /*
   * Los productos pueden estar duplicados así que buscamos todos.
   */
  update_local_products (products) {
    for (const product of products) {
      for (const local of this.productTargets.filter(local => local.dataset.cartVariantId === product.id)) {
        local.dataset.cartInStock = product.attributes.in_stock
        local.dataset.cartPrice = product.attributes.price

        local.querySelectorAll('[data-stock-add]').forEach(button => button.disabled = !product.attributes.in_stock)
        local.querySelectorAll('[data-stock-price]').forEach(price => price.innerText = product.attributes.display_price)
      }
    }
  }
}
