import { CartBaseController } from './cart_base_controller'

/*
 * Retrieves payment methods and redirect to external checkouts
 */
export default class extends CartBaseController {
  static targets = [ 'form', 'submit' ]

  async connect () {
    const orderToken = this.token
    const response = await this.spree.checkout.paymentMethods({ orderToken })

    if (response.isFail()) {
      this.handleFailure(response)
      return
    }

    const payment_methods = response.success().data
    const site = await this.site()
    const cart = this.cart
    const next = { url: this.data.get('nextUrl') }
    const back = { url: this.data.get('backUrl') }

    this.render({ payment_methods, site, cart, next, back })
  }

  async render (data = {}) {
    const request = await fetch(this.data.get('template'))
    const template = await request.text()

    this.element.innerHTML = await this.engine.parseAndRender(template, data)

    if (!this.hasSubmitTarget) return
    this.formTarget.elements.forEach(p => p.addEventListener('change', e => this.submitTarget.disabled = false))
  }

  async pay (event) {
    event.preventDefault()
    event.stopPropagation()

    this.formDisabled = true

    const payment_method_id = this.formTarget.elements.payment_method_id.value
    const orderToken = this.token

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate({ orderToken },
      {
        order: { payments_attributes: [{ payment_method_id }] },
        payment_source: {
          [payment_method_id]: {
            name: 'Pepitx',
            month: 12,
            year: 2020
          }
        }
      })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.cart = response

    response = await this.spree.checkout.complete({ orderToken })

    if (response.isFail()) {
      this.handleFailure(response)
      this.formDisabled = false
      return
    }

    this.cart = response

    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken })
    let redirectUrl = this.data.get('nextUrl')

    if (checkoutUrls.data.length > 0) redirectUrl = checkoutUrls.data[0]

    try {
      Turbolinks.visit(redirectUrl)
    } catch {
      window.location = redirectUrl
    }
  }
}
