import { Notifier } from '@airbrake/browser'

window.airbrake = new Notifier({
  projectId: window.env.AIRBRAKE_PROJECT_ID,
  projectKey: window.env.AIRBRAKE_PROJECT_KEY,
  host: 'https://panel.sutty.nl'
})

import 'core-js/stable'
import 'regenerator-runtime/runtime'

const Turbolinks = require("turbolinks")
Turbolinks.start()

import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import { makeClient } from '@spree/storefront-api-v2-sdk'
import { Sutty } from './endpoints/sutty'

window.spree = makeClient({ host: window.env.SPREE_URL })
window.spree.sutty = new Sutty(window.spree.host)

// Prevenir que Turbolinks interfiera con la navegación por anchors
// https://github.com/turbolinks/turbolinks/issues/75#issuecomment-445325162
document.addEventListener('turbolinks:click', event => {
  const anchorElement = event.target
  const isSamePageAnchor = (
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname
  )

  if (!isSamePageAnchor) return

  Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(event.data.url, Turbolinks.uuid())

  event.preventDefault()
})

try {
  window.axe = require('axe-core/axe')
} catch(e) {}

if (window.axe) window.axe.configure({ locale: require('axe-core/locales/es.json') })

// Prevenir que Turbolinks interfiera la navegación a un anchor al
// recargar la página
document.addEventListener('turbolinks:load', event => {
  if (window.location.hash !== '') window.location.hash = window.location.hash

  if (!window.axe) return

  window.axe.run().then(results => {
    results.violations.forEach(violation => {
      violation.nodes.forEach(node => {
        node.target.forEach(target => {
          document.querySelectorAll(target).forEach(element => {
            element.classList.add('inaccesible')
            element.ariaLabel = node.failureSummary
          })
        })
      })
    })
  })
})
