import { CartBaseController } from './cart_base_controller'

export default class extends CartBaseController {
  static targets = [ 'form', 'username' ]

  connect () {
    if (!this.hasUsernameTarget) return
    if (!this.hasFormTarget) return

    this.formTarget.addEventListener('focusout', event => {
      if (!this.formTarget.checkValidity()) {
        this.formTarget.classList.add('was-validated')
        return
      }

      this.formTarget.classList.remove('was-validated')

      const username = this.usernameTarget.value.trim()
      if (username.length === 0) return

      this.email = username
    })
  }
}
