import { CartBaseController } from './cart_base_controller'

export default class extends CartBaseController {
  static targets = [ 'counter' ]

  connect () {
    if (!this.hasCounterTarget) {
      console.error("Missing counter target")
      return
    }

    window.addEventListener('cart:counter', event => this.counter = event.detail.item_count)
    window.addEventListener('storage', event => {
      if (event.key == 'cart:counter') this.counter = event.newValue
    })

    if (!this.cart) return

    this.counter = this.cart.data.attributes.item_count
  }

  set counter (quantity) {
    this.counterTarget.innerText = quantity
  }
}
