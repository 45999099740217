import Axios from 'axios'
import * as qs from 'qs'

/*
 * XXX: We're copying code from @spree/storefront-api-v2-sdk/src/Http.ts
 * because we don't know how to mix Typescript :D
 */
export class Sutty {
  constructor (host = 'http://localhost:3000') {
    this.host = host

    this.axios = Axios.create({
      baseURL: this.host,
      headers: { 'Content-Type': 'application/json' },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
    })
  }

  async getCheckoutURL(tokens = {}) {
    const headers = this.spreeOrderHeaders(tokens)
    const axiosConfig = {
      url: 'api/v2/storefront/checkout_redirect.json',
      params: {},
      method: 'get',
      headers
    }

    return await this.axios(axiosConfig)
  }

  async assignOrderOwnership(tokens = {}, params = {}) {
    const headers = this.spreeOrderHeaders(tokens)
    const axiosConfig = {
      url: 'api/v2/storefront/assign_order_ownership.json',
      params,
      method: 'post',
      headers
    }

    return await this.axios(axiosConfig)
  }

  async assignOrderShippingAddress(tokens = {}, params = {}) {
    const headers = this.spreeOrderHeaders(tokens)
    const axiosConfig = {
      url: 'api/v2/storefront/assign_order_shipping_address.json',
      params,
      method: 'post',
      headers
    }

    return await this.axios(axiosConfig)
  }

  async assignOrderBillingAddress(tokens = {}, params = {}) {
    const headers = this.spreeOrderHeaders(tokens)
    const axiosConfig = {
      url: 'api/v2/storefront/assign_order_billing_address.json',
      params,
      method: 'post',
      headers
    }

    return await this.axios(axiosConfig)
  }

  spreeOrderHeaders(tokens) {
    const header = {}

    if (tokens.orderToken) {
      header['X-Spree-Order-Token'] = tokens.orderToken
    }

    if (tokens.bearerToken) {
      header['Authorization'] = `Bearer ${tokens.bearerToken}`
    }

    return header
  }
}
